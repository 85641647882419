import { setCookie } from '../utils/coockie/setCookie';
import { getCookie } from '../utils/coockie/getCookie';

export function initJivosite() {
    const jivoChatHashCookie = Boolean(getCookie('jivoChatHash'));

    if (jivoChatHashCookie) {
        appendChat();
        listenerChat();
        setJivoChatDataToCookie();
    } else {
        let userInteracted = false;

        function userActionListener() {
            if (!userInteracted) {
                userInteracted = true;
                setTimeout(function () {
                    appendChat();
                    listenerChat();
                }, 5000);
                removeUserActionListeners();
            }
        }

        function addUserActionListeners() {
            document.addEventListener('click', userActionListener);
            document.addEventListener('mousemove', userActionListener);
            document.addEventListener('keydown', userActionListener);
            document.addEventListener('scroll', userActionListener);
        }

        function removeUserActionListeners() {
            document.removeEventListener('click', userActionListener);
            document.removeEventListener('mousemove', userActionListener);
            document.removeEventListener('keydown', userActionListener);
            document.removeEventListener('scroll', userActionListener);
        }

        addUserActionListeners();
    }

    // FUNCTIONS
    function appendChat() {
        const body = document.body;
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = `//code.jivosite.com/widget/${window.glassagremcomJivoChatHash}`;
        newScript.defer = true;
        body.appendChild(newScript);
    }

    function listenerChat() {
        const listenLoadChat = setInterval(timerListenLoadChat, 100);

        function timerListenLoadChat() {
            if (Boolean(window.jivo_api)) {
                window.jivo_onMessageSent = function jivo_onMessageSent() {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatHash', window.glassagremcomJivoChatHash, cookieOptions);
                };

                window.jivo_api.getVisitorNumber(function (error, visitorNumber) {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatVisitor', visitorNumber, cookieOptions);
                });

                stopListenLoadChat();
                jivo_onLoadCallback();
            }
        }

        function stopListenLoadChat() {
            clearInterval(listenLoadChat);
        }
    }

    function appendSessionToCookies(cookieName, value) {
        const cookieOptions = {
            path: '/',
        };

        setCookie(cookieName, value, cookieOptions);
    }

    function setJivoChatDataToCookie() {
        const submits = document.querySelectorAll('[data-jivo-chat-cookie]');

        submits.forEach((submit) => {
            submit.addEventListener('click', function (e) {
                const hashClient = `jv_store_${window.glassagremcomJivoChatHash}_client`;
                const hashApp = `jv_store_${window.glassagremcomJivoChatHash}_app`;

                const jv_store_client = localStorage.getItem(hashClient);
                const jv_store_app = localStorage.getItem(hashApp);

                if (!Boolean(jv_store_client) || !Boolean(jv_store_app)) return;

                const jivoChatSession = {
                    [hashClient]: JSON.parse(jv_store_client),
                    [hashApp]: JSON.parse(jv_store_app),
                };

                appendSessionToCookies('jivoChatSession', JSON.stringify(jivoChatSession));
            });
        });
    }

    function jivo_onLoadCallback() {
        window.jivo_api.setContactInfo({
            name: document.querySelector('[data-email]').getAttribute('data-email'),
            email: document.querySelector('[data-email]').getAttribute('data-email'),
        });
        window.jivo_api.setCustomData([
            {
                title: 'Brand',
                content: document.querySelector('[data-brand]').getAttribute('data-brand'),
            },
        ]);
    }
}
