import './components/PageLoader';
import { initScrollLocker } from './assets/initScrollLocker';
import { MobileMenu } from './components/MobileMenu';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { scrollTo } from './components/helper-function/scrollTo';
import { initStopAnimateOnResize } from './assets/initStopAnimateOnResize';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';
import { initJivosite } from './components/initJivosite';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handleOnLoadPage() {
    initScrollLocker();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    window.mobileMenu = new MobileMenu();
    scrollTo();
    initScrollToAnchor();
    initStopAnimateOnResize();
    initMixpannel();
    initLangSwitcher();
    initHashEmail();
    initJivosite();
}
