function PageLoader() {
    // VARS
    const $this = this;
    const loader = document.querySelector('[data-page-loader]');
    const hiddenClass = 'page-loader--hidden';

    // METHODS
    $this.show = function show() {
        loader?.classList.remove(hiddenClass);
    };

    $this.hide = function hide() {
        loader?.classList.add(hiddenClass);
    };
}

window.pageLoader = new PageLoader();

window.addEventListener(
    'beforeunload',
    () => {
        window.pageLoader.show();
    },
    { capture: true },
);

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

setTimeout(function () {
    window.pageLoader.hide();
}, 1000);
